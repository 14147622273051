/* body{
    background-image: linear-gradient(#355C7D, #C06C84);
}
#app{
    height: 100%;
} */

@import "assets/theme.css";
@import "primeng/resources/primeng.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@font-face {
    font-family: 'Poppins';
    src: url('./assets/Poppins/Poppins-Medium.ttf');
  }
  @font-face {
    font-family: 'Poppins light';
    src: url('./assets/Poppins/Poppins-Light.ttf');
  }
  @font-face {
    font-family: 'Pacifico';
    src: url('./assets/Pacifico.ttf');
  }
  @import "primeicons/primeicons.css";
  
:root{
    --heading-color:#fff;
    --background-color :var(--surface-ground) /*#151419*/;
    --frame-color : var(--surface-overlay) /*#23242A*/;
    --element-color: #252A34;
    --primary-gradient:linear-gradient(180deg, #E78A3C 0%, #DA796D 27.6%, #D06B93 67.71%, #C45CBF 100%);
    --secondary-color: #6DDCFF;
    --helper-text-color: #71717A;
    --navbar-height:80px;
    --surface-card:var(--frame-color)
}
html{
    background-color: var(--background-color)
    ;
}
body{
    background-color: transparent !important;
  min-height: 100vh; margin:0; 
  display:flex; flex-direction:column;
  /* max-width: 1440px; */
  margin-right: auto !important;
  margin-left: auto !important;
    
}


h1,h2,h3,h4,h5,h6,p,label,td,th,li,a{
    color: white !important;
    
    
    
}
a{
    text-decoration: none;
}
* {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    }

.jukrContainer{

   
}
.white{
    color: white !important;
}
.helper-span{
    color: var(--helper-text-color)
}
.separator{
    width: 100%; height: 1px; visibility: visible; border-bottom: 1px solid rgba(55, 53, 47, 0.16); background-color: var(--helper-text-color);
    vertical-align:middle;
    margin: auto 0px;
}
.logon-card{
    max-width: 500px;
    display: flex;
    justify-content: center;

}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.card-shadow{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.jukr-card{
    background-color: var(--frame-color) !important;
    border-radius: 10px !important;
    align-self: center !important;

}
.fw-r{
font-family: 'Poppins light';
    font-weight: 400 !important;
}
.form-label{
    color: white;
   
}
.otp-input{
    background-color: var(--element-color) !important;
}
.form-input{
    width: 100%;
    background-color: var(--element-color);
    height: 34px;
    border: none;
    color: white;
    padding: 5px 20px;
}
.form-input:focus :active :focus-visible{
    border: none;
}
.form-input:focus-visible{
    border: none;
    outline: none;
}
.text-decorate-icon{
    color: white;
    border-color: #5C5656 !important;
    border-right:1px solid #5C5656;

}
.text-decorate-input{
    background-color: transparent !important;
    line-height: inherit !important;
    display: flex !important;
    width: 100% !important;
    color: white !important;
    font-size: inherit !important;
}
.gp-phone-number input{
    border: 0px;
    width: auto;
   
 }
 .gp-phone-number input:focus{
     border: none;
     box-shadow: none;
 }
 .gp-phone-number{
 
     background-color: var(--element-color);
     padding: 5px;
     line-height: 24px;
     font-size: medium;
 
 }
 /* input::placeholder {
    color: white;
    opacity: 0.1;
}

input:-ms-input-placeholder { 
    color: white;
    opacity: 0.1;
}
input::-ms-input-placeholder {
    color: white;
    opacity: 0.1;
}
input:focus-visible{
    outline:none
} */
.primary-btn{
    text-align: center;
    width: 100%;
    color: white;
    background-image: var(--primary-gradient);
    border: none;
    border-radius: 10px;
    height: 40px;    
}
.primary-btn span{
   
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center !important;
color: white;
    margin: auto 0px;
}

.secondary-btn {
    text-align: center;
    width: 100%;
    color: white;
    background-image: var(--primary-gradient);
    border: none;
    border-radius: 10px;
    height: 40px;  
    padding: 2px;  
}
.secondary-btn button{
    text-align: center;
    width: 100%;
    color: white;
    background-color: var(--frame-color);
    border: none;
    border-radius: 10px;
    height: 100%;    
}
.secondary-btn span{
   
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center !important;
color: white;
    margin: auto 0px;
}
.btn-primary-used{
    color:var(--helper-text-color) !important ;
}
.error-snackbar{
    background-color: red;
    color: white;
}
p-dialog .p-dialog-header,.p-dialog-content{
    background-color: var(--frame-color) !important;

}
.p-dialog-header{
    background-color: var(--frame-color) !important;
}
.p-dialog{
    /* padding:20px */
}
.bg-frame{
    background-color: var(--frame-color) !important;
}
.disabled{
    color:rgba(255,255, 255, 0.5) !important;
    pointer-events: none;
}
/* .p-overlaypanel::before,.p-overlaypanel::after{
    display: none;
}
.p-overlaypanel .p-overlaypanel-content{
    padding: 5px !important;
} */
/* .p-calendar .p-datepicker{
    background-image: var(--primary-gradient) !important;
}
.p-datepicker-header{
    background-color: white !important;
}
.p-datepicker .p-timepicker button{
    color: white !important;
}
.p-datepicker .p-timepicker button:hover{
    background: var(--frame-color) !important;
}
.p-highlight{
    color:var(--background-color) !important
}
.p-datepicker-today span{
    color:var(--background-color) !important
}
.p-calendar{
    height: 34px;
}
.p-calendar .p-inputtext{
    font-family: 'Poppins';
    border-radius: 0px;
    background: var(--element-color) !important;
    color: white;
    border: none;
}
.p-calendar-w-btn .p-datepicker-trigger{
    background: var(--element-color);
    color: white;
    border: none;
}

.p-calendar-w-btn .p-datepicker-trigger:hover{
    background: none !important;
    border: none;
    background-image: var(--primary-gradient) !important;
} */
/* .p-button:enabled:hover{
    background: none !important;
} */
footer div a{
    color: rgba(255, 255, 255, 0.747);text-decoration: none;
}
footer div a:hover{
    text-decoration: underline;
}
.error-bar{
    z-index: 150000;
}
.request-card{
    background-color: var(--frame-color);
    border-radius: 10px;

}
.event-table-header th{
    background-color:  var(--element-color) !important;
    border: none;
}
.event-table-data, .event-table-data td{
    background-color: transparent !important;
    border-bottom: solid 1px var(--helper-text-color);
    transition: 0.3s;
}
.event-table-data:hover{
    background-color: rgba(255,255,255,0.1) !important
}
.header-icon{
    transition: all .2s ease-in-out; 
}
.header-icon:hover{
    transform: scale(1.1); 
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 0.5rem !important;
    height: 0.5rem !important;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50% !important;
}
.p-carousel .p-carousel-indicators{
    padding: 0px !important;
}
.nav-link:focus, .nav-link:hover {
    color: var(--secondary-color) !important;
}
.p-inputtext{
    background-color: var(--element-color) !important;
    /* border-color: var(--background-color) !important; */
}
.p-inputtext:focus{
    box-shadow:0 0 0 0.2rem var(--background-color) !important;
}
.disabled-input{
    background-color: var(--element-color);
    opacity: 0.5;
}
.p-datepicker-mask-scrollblocker{
    z-index: 1100 !important;
}